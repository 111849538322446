.a-prizeTag {
    background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
    border: 3px solid $shampoo;
    border-radius: rem(72);
    padding: rem(14) rem(36);
    width: fit-content;
    transition: all 0.3s ease-in-out;
    box-shadow: -1.9px 2.2px 4px 2px $deep-violet-07;
    position: relative;

    @include max-large-desktop-down {
        padding: rem(10) rem(32);
    }

    @include max-desktop-down {
        padding: rem(6) rem(28);
    }

    &_text {
        text-transform: uppercase;
        color: $white;
        @include font-base(34, 44);
        font-family: $font-gotham;

        @include max-large-desktop-down {
            @include font-base(28, 36);
        }

        @include max-desktop-down {
            @include font-base(24, 30);
        }
    }
    
    &_textDetail {
        text-transform: uppercase;
        color: $white;
        @include font-base(26, 32);
        font-family: $font-gotham;
        margin-top: rem(8);

        @include max-large-desktop-down {
            @include font-base(22, 26);
        }

        @include max-desktop-down {
            @include font-base(18, 22);
        }
    }

    &_img {
        position: absolute;
        width: 80%;
        left: 50%;
        // mix-blend-mode: screen;
        z-index: 1;
    }

    &_stars {
        position: absolute;
        width: 100%;
        max-width: rem(150);
        top: rem(-64);
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include max-desktop-down {
            max-width: rem(100);
            top: rem(-44);
        }
    }

    &_stars5 {
        position: absolute;
        width: 100%;
        max-width: rem(300);
        top: rem(-108);
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include max-desktop-down {
            max-width: rem(200);
            top: rem(-74);
        }
    }
}
