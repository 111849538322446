.o-table {
    border-radius: rem(16);
    background-color: $white;
    padding: rem(16);
    box-shadow: -2px 1px 5px 4px #3f00686b;
    font-family: $font-gotham;

    &_title {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: rem(12);
        color: $purpureus-2;
        @include font-base(17, 21);
        text-align: center;
        border-bottom: 1px solid $purpureus-2;
    }

    &_tableWrap {
        width: 100%;
        margin-top: rem(8);
        border-collapse: collapse;
    }

    th,
    td {
        @include font-base(10, 12);
        color: $black;
        text-align: left;
        padding-top: rem(5);
        padding-bottom: rem(5);

        &:first-child {
            width: 35%;
        }

        &:last-child {
            text-align: right;
            width: 28%;
        }
    }

    &_head {
        font-family: $font-gotham-book;

        i {
            display: inline-block;
            vertical-align: middle;
            margin-left: rem(4);
            cursor: pointer;
            flex-shrink: 0;
        }
    }

    &_body {
        tr {
            transition: all 0.3s ease;

            &:hover {
                background-color: $anti-flash-white;
            }

            &:last-child {
                td {
                    padding-bottom: 0;
                }
            }
        }
    }
}
