@import 'styles/common';
@import '~bootstrap/dist/css/bootstrap-grid.min.css';
@import '~tfc-components/dist/styles.css';

// Components
@import 'components/atoms/ButtonCustom/index.scss';
@import 'components/atoms/Checkbox/index.scss';
@import 'components/atoms/Error/index.scss';
@import 'components/atoms/Example/index.scss';
@import 'components/atoms/Icon/index.scss';
@import 'components/atoms/InputCustom/index.scss';
@import 'components/atoms/LoadingFullscreen/index.scss';
@import 'components/atoms/PrizeTag/index.scss';
@import 'components/molecules/ButtonLuckyDraw/index.scss';
@import 'components/molecules/Example/index.scss';
@import 'components/molecules/FormField/index.scss';
@import 'components/molecules/Pulldown/index.scss';
@import 'components/organisms/Example/index.scss';
@import 'components/organisms/FramePrize/index.scss';
@import 'components/organisms/IntroduceNabati/index.scss';
@import 'components/organisms/Modal/index.scss';
@import 'components/organisms/Navigation/index.scss';
@import 'components/organisms/NotifyLuckyDraw/index.scss';
@import 'components/organisms/Prize/index.scss';
@import 'components/organisms/PrizeModal/index.scss';
@import 'components/organisms/Table/index.scss';
@import 'components/organisms/TableResult/index.scss';
@import 'components/organisms/TableSpecialPrize/index.scss';
@import 'components/templates/DrawLayout/index.scss';
@import 'components/templates/MainLayout/index.scss';
@import 'pages/CommingSoon/index.scss';
@import 'pages/Contact/index.scss';
@import 'pages/CreateTopUp/index.scss';
@import 'pages/CustomerInfo/index.scss';
@import 'pages/EndPhase/index.scss';
@import 'pages/Home/index.scss';
@import 'pages/LuckyDraw/index.scss';
@import 'pages/PostCallCenter/index.scss';
@import 'pages/Privacy/index.scss';
@import 'pages/Prize/index.scss';
@import 'pages/PrizeFirst/index.scss';
@import 'pages/PrizeSpecial/index.scss';
@import 'pages/Tnc/index.scss';
@import 'pages/UpdatingPrizes/index.scss';
