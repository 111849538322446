/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Attention seekers ----------------------------------- */
/* ----------------------------------------------------------------------------------------- */

@keyframes bounce {
    from,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
        transform: translate3d(0, 0, 0) scaleY(0.95);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    90% {
        transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
}
@keyframes flash {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
@keyframes headShake {
    0% {
        transform: translateX(0);
    }

    6.5% {
        transform: translateX(-6px) rotateY(-9deg);
    }

    18.5% {
        transform: translateX(5px) rotateY(7deg);
    }

    31.5% {
        transform: translateX(-3px) rotateY(-5deg);
    }

    43.5% {
        transform: translateX(2px) rotateY(3deg);
    }

    50% {
        transform: translateX(0);
    }
}
@keyframes heartBeat {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.3);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.3);
    }

    70% {
        transform: scale(1);
    }
}
@keyframes jello {
    from,
    11.1%,
    to {
        transform: translate3d(0, 0, 0);
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
@keyframes rubberBand {
    from {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
@keyframes shakeX {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}
@keyframes shakeY {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(0, -10px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(0, 10px, 0);
    }
}
@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
@keyframes wobble {
    from {
        transform: translate3d(0, 0, 0);
    }

    15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wobble-slow {
    from {
        transform: translate3d(0, 0, 0);
    }

    15% {
        transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    30% {
        transform: translate3d(2%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        transform: translate3d(0%, 0, 0) rotate3d(0, 0, 0, -3deg);
    }

    60% {
        transform: translate3d(2%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    75% {
        transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Back entrances -------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes backInDown {
    0% {
        opacity: 0.7;
        transform: translateY(-1200px) scale(0.7);
    }

    80% {
        opacity: 0.7;
        transform: translateY(0) scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes backInLeft {
    0% {
        opacity: 0.7;
        transform: translateX(-2000px) scale(0.7);
    }

    80% {
        opacity: 0.7;
        transform: translateX(0) scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes backInRight {
    0% {
        opacity: 0.7;
        transform: translateX(2000px) scale(0.7);
    }

    80% {
        opacity: 0.7;
        transform: translateX(0) scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes backInUp {
    0% {
        opacity: 0.7;
        transform: translateY(1200px) scale(0.7);
    }

    80% {
        opacity: 0.7;
        transform: translateY(0) scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* -------------------------------------- Back exits --------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes backOutDown {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.7;
        transform: translateY(0) scale(0.7);
    }

    100% {
        opacity: 0.7;
        transform: translateY(700px) scale(0.7);
    }
}
@keyframes backOutLeft {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.7;
        transform: translateX(0) scale(0.7);
    }

    100% {
        opacity: 0.7;
        transform: translateX(-2000px) scale(0.7);
    }
}
@keyframes backOutRight {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.7;
        transform: translateX(0) scale(0.7);
    }

    100% {
        opacity: 0.7;
        transform: translateX(2000px) scale(0.7);
    }
}
@keyframes backOutUp {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.7;
        transform: translateY(0) scale(0.7);
    }

    100% {
        opacity: 0.7;
        transform: translateY(-700px) scale(0.7);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ---------------------------------- Bouncing entrances ----------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0) scaleY(3);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, -10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, 5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0) scaleX(1);
    }

    75% {
        transform: translate3d(-10px, 0, 0) scaleX(0.98);
    }

    90% {
        transform: translate3d(5px, 0, 0) scaleX(0.995);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0) scaleX(1);
    }

    75% {
        transform: translate3d(10px, 0, 0) scaleX(0.98);
    }

    90% {
        transform: translate3d(-5px, 0, 0) scaleX(0.995);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0) scaleY(5);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------ Bouncing exits ------------------------------------- */
/* ----------------------------------------------------------------------------------------- */

@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
@keyframes bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0) scaleY(3);
    }
}
@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0) scaleX(2);
    }
}
@keyframes bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0) scaleX(2);
    }
}
@keyframes bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0) scaleY(3);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Fading entrances ------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeInBottomLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInBottomRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInDownBig {
    from {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInRightBig {
    from {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInTopLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInTopRight {
    from {
        opacity: 0;
        transform: translate3d(100%, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInUpBig {
    from {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------- Fading exits -------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes fadeOutBottomLeft {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 100%, 0);
    }
}
@keyframes fadeOutBottomRight {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 100%, 0);
    }
}
@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}
@keyframes fadeOutDownBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}
@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}
@keyframes fadeOutLeftBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}
@keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}
@keyframes fadeOutRightBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}
@keyframes fadeOutTopLeft {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, -100%, 0);
    }
}
@keyframes fadeOutTopRight {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(100%, -100%, 0);
    }
}
@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}
@keyframes fadeOutUpBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* --------------------------------------- Flippers ---------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes flip {
    from {
        animation-timing-function: ease-out;
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    }

    40% {
        animation-timing-function: ease-out;
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    }

    50% {
        animation-timing-function: ease-in;
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    }

    80% {
        animation-timing-function: ease-in;
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    }

    to {
        animation-timing-function: ease-in;
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    }
}
@keyframes flipInX {
    from {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    }

    40% {
        animation-timing-function: ease-in;
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }

    60% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}
@keyframes flipInY {
    from {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    }

    40% {
        animation-timing-function: ease-in;
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    }

    60% {
        opacity: 1;
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    }

    80% {
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}
@keyframes flipOutX {
    from {
        transform: perspective(400px);
    }

    30% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    }
}
@keyframes flipOutY {
    from {
        transform: perspective(400px);
    }

    30% {
        opacity: 1;
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* -------------------------------------- Lightspeed --------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes lightSpeedInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0) skewX(30deg);
    }

    60% {
        opacity: 1;
        transform: skewX(-20deg);
    }

    80% {
        transform: skewX(5deg);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes lightSpeedInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0) skewX(-30deg);
    }

    60% {
        opacity: 1;
        transform: skewX(20deg);
    }

    80% {
        transform: skewX(-5deg);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes lightSpeedOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0) skewX(-30deg);
    }
}
@keyframes lightSpeedOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0) skewX(30deg);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Rotating entrances ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes rotateIn {
    from {
        opacity: 0;
        transform: rotate3d(0, 0, 1, -200deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotateInDownLeft {
    from {
        opacity: 0;
        transform: rotate3d(0, 0, 1, -45deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotateInPosition {
    from {
        opacity: 0;
        transform: rotate3d(0, 0, 1, 45deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotateInUpRight {
    from {
        opacity: 0;
        transform: rotate3d(0, 0, 1, -90deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------- Rotating exits ------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
@keyframes rotateOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: rotate3d(0, 0, 1, 200deg);
    }
}
@keyframes rotateOutPositive {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: rotate3d(0, 0, 1, 45deg);
    }
}
@keyframes rotateOutNegative {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: rotate3d(0, 0, 1, -45deg);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ---------------------------------------- Specials --------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes hinge {
    0% {
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        animation-timing-function: ease-in-out;
        transform: rotate3d(0, 0, 1, 80deg);
    }

    40%,
    80% {
        animation-timing-function: ease-in-out;
        opacity: 1;
        transform: rotate3d(0, 0, 1, 60deg);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 700px, 0);
    }
}
@keyframes jackInTheBox {
    from {
        opacity: 0;
        transform: scale(0.1) rotate(30deg);
        transform-origin: center bottom;
    }

    50% {
        transform: rotate(-10deg);
    }

    70% {
        transform: rotate(3deg);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes rollIn {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes rollOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------ Zooming entrances ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}
@keyframes zoomInDown {
    from {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    }
}
@keyframes zoomInLeft {
    from {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    }
}
@keyframes zoomInRight {
    from {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    }
}
@keyframes zoomInUp {
    from {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------ Zooming exits ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}
@keyframes zoomOutDown {
    40% {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    }

    to {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    }
}
@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }

    to {
        opacity: 0;
        transform: scale(0.1) translate3d(-2000px, 0, 0);
    }
}
@keyframes zoomOutRight {
    40% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    to {
        opacity: 0;
        transform: scale(0.1) translate3d(2000px, 0, 0);
    }
}
@keyframes zoomOutUp {
    40% {
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    }

    to {
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ------------------------------------ Sliding entrances ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes slideInDown {
    from {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
/* ----------------------------------------------------------------------------------------- */
/* -------------------------------------- Sliding exits ------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
@keyframes slideOutDown {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 100%, 0);
        visibility: hidden;
    }
}
@keyframes slideOutLeft {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(-100%, 0, 0);
        visibility: hidden;
    }
}
@keyframes slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(100%, 0, 0);
        visibility: hidden;
    }
}
@keyframes slideOutUp {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, -100%, 0);
        visibility: hidden;
    }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Bounce hover card ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes bounceY {
    0% {
        transform: translateY(-205px);
    }

    40% {
        transform: translateY(-100px);
    }

    65% {
        transform: translateY(-52px);
    }

    82% {
        transform: translateY(-25px);
    }

    92% {
        transform: translateY(-12px);
    }

    55%,
    75%,
    87%,
    97%,
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeInStar {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}
