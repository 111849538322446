.o-modal {
    $root: &;
    height: 100%;
    outline: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        max-width: rem(600);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding: rem(20);
        width: 100%;
        position: relative;
    }

    &_close {
        position: absolute;
        left: 50%;
        bottom: rem(-12);
        transform: translateX(-50%);
        z-index: 104;
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        box-shadow: -2px 1px 5px 4px #3f00686b;
    }

    &_wrapper {
        border-radius: rem(4);
        background-color: transparent;
        position: relative;
        width: 100%;
        z-index: 103;
    }

    &_content {
        background-color: $white;
        border-radius: rem(12);
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 103;
        box-shadow: -2px 1px 5px 4px #3f00686b;
    }

    &_body {
        height: 100%;
        overflow: hidden auto;
        padding: rem(28) rem(32) rem(16);
        max-height: 80vh;

        @include mobile-down {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    &-maxWidth600 {
        #{$root}_main {
            max-width: rem(600);
        }
    }

    &_star1,
    &_star2,
    &_star3,
    &_star4,
    &_star5 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }


    &_star1 {
        &::before {
            position: absolute;
            content: '';
            background: url('~assets/images/modal/star1.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(58);
            height: rem(41);
            top: rem(-24);
            left: 50%;
            animation: fade-up-star1 0.6s ease-in both;
        }
    }

    &_star2 {
        &::before {
            position: absolute;
            content: '';
            background: url('~assets/images/modal/star2.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(48);
            height: rem(75);
            top: rem(-44);
            left: 50%;
            animation: fade-up-star2 0.8s ease-in both;
        }
    }


    &_star3 {
        &::before {
            position: absolute;
            content: '';
            background: url('~assets/images/modal/star3.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(45);
            height: rem(126);
            top: rem(-62);
            left: 50%;
            animation: fade-up-star3 0.8s ease-in both;
        }
    }

    &_star4 {
        &::before {
            position: absolute;
            content: '';
            background: url('~assets/images/modal/star4.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(48);
            height: rem(75);
            top: rem(-48);
            left: 50%;
            animation: fade-up-star4 0.8s ease-in both;
        }
    }

    &_star5 {
        &::before {
            position: absolute;
            content: '';
            background: url('~assets/images/modal/star5.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: rem(58);
            height: rem(41);
            top: rem(-24);
            left: 50%;
            animation: fade-up-star5 0.6s ease-in both;
        }
    }

    &_light {
        position: absolute;
        width: rem(360);
        top: rem(-110);
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include break-max(320px) {
            width: rem(280);
            top: rem(-90);
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &-failPrize {
        #{$root} {
            &_body {
                padding-bottom: rem(44);
            }
        }
    }

    &-winPrize {
        #{$root} {
            &_body {
                padding-bottom: rem(44);
            }
        }
    }

    &-privacy {
        background: url('~assets/images/background.jpg') no-repeat;
        background-position: center;
        background-size: cover;

        #{$root}_main {
            align-items: flex-start;
            padding-top: rem(40);
        }

        #{$root}_wrapper {
            height: 100%;
        }

        #{$root}_content {
            height: 65%;
            margin-top: rem(20);
        }

        #{$root}_newArrivals {
            width: 100%;
            max-width: rem(220);
            bottom: rem(-160);
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
            margin-top: rem(20);
        }
    }

    &_overlay {
        &-privacy {
            overflow-y: auto;
            max-height: calc(100vh - 44px);

            &::after {
                background: #0006 !important;
            }
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba($black, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba(200, 200, 200, 0.3);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@keyframes fade-up-star1 {
    0% {
        transform: translate(-134%, 100%);
    }

    100% {
        transform: translate(-134%, 0);
    }
}

@keyframes fade-up-star2 {
    0% {
        transform: translate(-114%, 100%);
    }

    100% {
        transform: translate(-114%, 0);
    }
}

@keyframes fade-up-star3 {
    0% {
        transform: translate(-50%, 100%);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes fade-up-star4 {
    0% {
        transform: translate(14%, 100%);
    }

    100% {
        transform: translate(14%, 0);
    }
}

@keyframes fade-up-star5 {
    0% {
        transform: translate(26%, 100%);
    }

    100% {
        transform: translate(26%, 0);
    }
}
