.p-commingSoon {
    flex-grow: 1;
    padding-top: rem(4);
    display: flex;
    flex-direction: column;

    &_heading {
        background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
        border: 1px solid $shampoo;
        border-radius: rem(16);
        padding: rem(8) rem(16);
        box-shadow: -1.9px 2.2px 4px 2px $deep-violet-07;
        z-index: 1;

        h2 {
            text-transform: uppercase;
            font-weight: 900;
            color: $white;
            @include font-base(13, 17);
            font-family: $font-gotham;
            text-align: center;
            text-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
        }
    }

    &_prizes {
        flex-grow: 1;
        padding: rem(10) rem(16);
    }

    &_footer {
        width: calc(100% + rem(32));
        background: url('~assets/images/footer.png') no-repeat;
        background-position: top center;
        background-size: cover;
        padding: rem(32) rem(32) rem(16);
        margin-left: rem(-16);
        display: flex;

        @media (max-width: 376px) {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        &_content {
            flex-grow: 1;
        }

        &_text {
            width: 100%;
            display: inline-block;
            color: $white;
            font-weight: 300;
            @include font-base(8, 10);
        }

        &_contact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(4);

            &_text {
                @include font-base(8, 10);
                color: $white;
                margin-left: rem(4);
            }
        }

        &_flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_qr {
            margin-left: rem(16);
            width: rem(72);
            flex-shrink: 0;
            @include font-base(7, 9);
            color: $white;

            @media (max-width: 376px) {
                margin-left: rem(12);
            }

            p {
                margin-top: rem(4);
                text-align: center;
            }
        }

        &_products {
            margin-top: rem(4);
            @include font-base(8.6, 11);
            color: $white;
        }

        &_list {
            @include font-base(7, 9);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            p {
                flex-basis: 48%;
                white-space: nowrap;
                margin-top: rem(2);

                &:nth-child(2n) {
                    text-align: right;
                }
            }
        }
    }
}
