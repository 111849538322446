.m-buttonLuckyDraw {
    $root: &;
    background: url('~assets/images/button_draw.svg') no-repeat center center;
    background-size: cover;
    position: relative;
    z-index: 2;
    width: rem(220);
    height: rem(220);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include max-large-desktop-down {
        width: rem(180);
        height: rem(180);
    }

    @include max-desktop-down {
        width: rem(150);
        height: rem(150);
    }

    &-special {
        width: rem(300);
        height: rem(300);

        @include max-large-desktop-down {
            width: rem(260);
            height: rem(260);
        }

        @include max-desktop-down {
            width: rem(180);
            height: rem(180);
        }

        #{$root}_inside {
            width: rem(200);
            height: rem(200);

            @include max-large-desktop-down {
                width: rem(180);
                height: rem(180);
            }

            @include max-desktop-down {
                width: rem(130);
                height: rem(130);
            }

            &::after {
                width: rem(200);
                height: rem(200);

                @include max-large-desktop-down {
                    width: rem(180);
                    height: rem(180);
                }

                @include max-desktop-down {
                    width: rem(130);
                    height: rem(130);
                }
            }

            >p {
                @include font-base(42, 58);

                @include max-large-desktop-down {
                    @include font-base(38, 48);
                }

                @include max-desktop-down {
                    @include font-base(24, 32);
                }
            }
        }
    }

    &_inside {
        width: rem(150);
        height: rem(150);
        transition: all 0.25s ease-in-out;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 0;
        outline: none;
        background-color: #ee203c;
        transform: scale(1);

        @include max-large-desktop-down {
            width: rem(120);
            height: rem(120);
        }

        @include max-desktop-down {
            width: rem(90);
            height: rem(90);
        }

        &:disabled {
            background-color: $silver;
            color: $platinum-8e8;
            cursor: initial;
        }

        &:active {
            transform: scale(0.95);
        }

        // &:hover {
        //     // effect suggest press to here use keyframe press
        //     animation: press 0.3s infinite alternate;

        //     @keyframes press {
        //         0% {
        //             transform: scale(1);
        //         }
        //         50% {
        //             transform: scale(0.95);
        //         }
        //         100% {
        //             transform: scale(1);
        //         }
        //     }
        // }

        &::after {
            content: '';
            position: absolute;
            width: rem(167);
            height: rem(167);
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            z-index: 1;
            background: linear-gradient(135deg, $white 0%, rgba($white, 0) 50%, rgba($white, 5%) 51%, rgba(#afbad5, 100%) 100%);
            border-radius: 50%;
            overflow: hidden;

            @include max-large-desktop-down {
                width: rem(140);
                height: rem(140);
            }

            @include max-desktop-down {
                width: rem(110);
                height: rem(110);
            }
        }

        >p {
            position: relative;
            z-index: 2;
            color: $white;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            @include font-base(38, 52);
            font-family: $font-gotham;

            @include max-large-desktop-down {
                @include font-base(32, 48);
            }

            @include max-desktop-down {
                @include font-base(21, 32);
            }
        }
    }

    &-animatePressSpace {
        animation: pressSpace 0.6s alternate;

        @keyframes pressSpace {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(0.9);
            }

            100% {
                transform: scale(1);
            }
        }
    }
}
