.o-prizeModal {
    &_header {
        @include font-base(19, 23);
        font-family: $font-gotham;
        color: $purpureus-2;
        text-align: center;
    }

    &_imgPrize {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(16);
    }

    &_priceTag {
        width: fit-content;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        min-width: rem(105);

        &::before {
            position: absolute;
            content: '';
            background: url('~assets/icons/ic_price.svg') no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: rem(37);
            z-index: -1;
        }
    }

    &_amount {
        color: $white;
        @include font-base(16, 20);
        font-family: $font-gotham;
    }

    &_vnd {
        color: $white;
        @include font-base(13, 16);
        font-family: $font-gotham;
    }

    &_priceWrap {
        text-align: center;
        margin-right: rem(18);
    }

    &_notifyText {
        @include font-base(16, 19);
        color: $black;
        font-family: $font-gotham;
        margin-top: rem(16);
    }

    &_description {
        @include font-base(13, 17);
        color: $black;
        font-family: $font-gotham;
        margin-top: rem(20);
    }

    &_topLeft {
        position: absolute;
        width: 100%;
        max-width: rem(93);
        top: rem(-50);
        left: 50%;
        animation: fade-down-left 1.5s ease-in both;
    }

    &_starAbs {
        position: absolute;
        top: 0;
    }

    &_starAbsBottom {
        position: absolute;
        top: rem(-78);

        .limit {
            animation-iteration-count: 1;
        }
    }

    &_star-11,
    &_star-15,
    &_star-17,
    &_star-19,
    &_star-21,
    &_star-24,
    &_star-28,
    &_star-31,
    &_star-42 {
        animation: fade-down-bottom 2s ease-in infinite;
    }

    &_star-13,
    &_star-22,
    &_star-32,
    &_star-27 {
        animation: fade-down-bottom 1.5s ease-in infinite;
    }

    &_star-12,
    &_star-14,
    &_star-16,
    &_star-18,
    &_star-23,
    &_star-26,
    &_star-33,
    &_star-41 {
        animation: fade-down-bottom 1.75s ease-in infinite;
    }

    &_star-25,
    &_star-43 {
        animation: fade-down-bottom 2.75s ease-in infinite;
    }

    &_topRight {
        position: absolute;
        width: 100%;
        max-width: rem(151);
        top: rem(-48);
        left: 50%;
        animation: fade-down-right 2s ease-in both;
    }

    &_bottomLeft {
        position: absolute;
        width: 100%;
        max-width: rem(93);
        bottom: rem(-12);
        left: rem(-12);
        animation: fade-down-bottom 2s ease-in both;
    }

    &_bottomRight {
        position: absolute;
        width: 100%;
        max-width: rem(73);
        bottom: rem(-14);
        right: rem(-14);
        animation: fade-down-bottom 1.5s ease-in both;
    }
}

@keyframes fade-down-left {
    0% {
        transform: translate(-190%, -100%);
    }

    100% {
        transform: translate(-190%, 0);
    }
}

@keyframes fade-down-right {
    0% {
        transform: translate(10%, -100%);
    }

    100% {
        transform: translate(10%, 0);
    }
}

@keyframes fade-down-bottom {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
