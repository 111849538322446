.o-tableSpecialPrize {
    padding: rem(24);
    background-color: $white;
    border-radius: rem(16);
    box-shadow: -2px 1px 5px 4px #3f00686b;

    @include max-large-desktop-down {
        padding: rem(20);
    }

    @include max-desktop-down {
        padding: rem(16);
    }

    &_header {
        padding: rem(8);
        border-radius: rem(8);
        color: $white;
        background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
    }

    &_headerText {
        text-transform: uppercase;
        @include font-base(28, 40);
        text-shadow: 2px 2px 4px rgba($black, 0.7);

        @include max-large-desktop-down {
            @include font-base(24, 36);
        }

        @include max-desktop-down {
            @include font-base(20, 32);
        }
    }

    &_info {
        @include font-base(28, 40);
        color: $dark-lavender;
        margin-top: rem(4);
        margin-bottom: rem(4);


        @include max-large-desktop-down {
            @include font-base(24, 36);
        }

        @include max-desktop-down {
            @include font-base(20, 32);
        }
    }

    &_item {
        text-align: center;
    }
}
