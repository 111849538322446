.p-updateingPrizes {
    flex-grow: 1;
    padding-top: rem(16);
    padding-bottom: rem(16);
    display: flex;
    flex-direction: column;

    &_heading {
        background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
        border: 1px solid $shampoo;
        border-radius: rem(16);
        padding: rem(12) rem(16);
        box-shadow: -1.9px 2.2px 4px 2px $deep-violet-07;
        z-index: 1;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-transform: uppercase;
            font-weight: 900;
            color: $white;
            @include font-base(18, 24);
            font-family: $font-gotham;
            text-align: center;
            text-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
        }
    }

    &_prize {
        flex-grow: 1;
        margin-top: -24%;
        position: relative;
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }
}
