.p-tnc {
    &_heading {
        margin-top: rem(32);
        color: $black;
        @include font-base(18, 22);
        font-family: $font-gotham;
        margin-bottom: rem(32);
        text-align: center;
        font-weight: 400;
    }

    &_content {
        word-break: break-word;
        font-family: $font-gotham;
        text-align: justify;
        font-size: rem(13);
        line-height: rem(18);
        font-weight: 400;
        margin-bottom: rem(32);
    }
}
