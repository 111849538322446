.p-prizeFirst {
    $root: &;
    position: relative;
    height: 100%;

    &_container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-top: calc(50 / 1024 * 100%);

        @include max-large-desktop-down {
            margin-top: calc(36 / 1024 * 100%);
        }

        &-special {
            margin-top: calc(10 / 1024 * 100%);

            &-result {
                margin-top: calc(50 / 1024 * 100%);
            }
        }

        &_left {
            width: calc(651 / 1920 * 100%);
            height: calc(577 / 1024 * 100%);

            img {
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
            }

            &_button {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &_right {
            width: calc(552 / 1920 * 100%);
            height: calc(552 / 1024 * 100%);

            img {
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
            }
        }

        &_center {
            width: calc(833 / 1920 * 100%);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }

        &-backup {
            margin-top: calc(20 / 1024 * 100%);

            @include max-large-desktop-down {
                margin-top: calc(10 / 1024 * 100%);
            }
        }

        .m-buttonLuckyDraw {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &_table {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_tableWrap {
        margin-top: rem(48);
        width: 80%;
    }

    &-result {
        .t-drawLayout_title {
            padding-top: calc(60 / 1080 * 100%);
        }

        #{$root}_container-backup {
            margin-top: calc(44 / 1024 * 100%);
        }
    }

    &-backup {
        .t-drawLayout_title {
            padding-top: calc(12 / 1080 * 100%);
        }

        .o-framePrize_item {
            margin-bottom: rem(20);
        }
    }

    &-resultBackup {
        .t-drawLayout_title {
            padding-top: calc(46 / 1080 * 100%);
        }
    }
}
