.a-error {
    padding-left: rem(6);
    padding-right: rem(12);
    background-color: $red-pigment;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: rem(3);
    margin-left: rem(8);

    .a-icon {
        flex-shrink: 0;
    }

    &_text {
        @include font-base(9, 16);
        color: $white;
        margin-left: rem(4);
        font-family: $font-gotham;
    }
}
