.a-button {
    $root: &;
    position: relative;
    background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
    border: 1px solid $shampoo;
    border-radius: rem(32);
    padding: 0 rem(16);
    width: fit-content;
    transition: all 0.3s ease-in-out;
    box-shadow: -1.9px 2.2px 4px 2px $deep-violet-07;
    z-index: 0;

    &_text {
        text-transform: uppercase;
        font-weight: 900 !important;
        color: $white;
        @include font-base(18, 22);
        font-family: $font-gotham;
    }

    &-disabled {
        cursor: not-allowed;

        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $black-03;
            border-radius: rem(32);
            top: 0;
            left: 0;
            z-index: 1;
            box-shadow: rem(-2) rem(2) rem(8) rgba($eminence, 0.5);
        }
    }

    &.a-button-disabled:hover {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &:active {
        transform: translateY(4px) scale(0.98);
    }

    &-text-wobble-slow {
        #{$root}_text {
            animation: wobble-slow 2.5s infinite;
        }
    }

    &-infinity-gradient {
        &::before {
            content: '';
            background: linear-gradient(15deg, $white 9.55%, $medium-orchid 30.68%, rgba($white, 0.4) 50.68%, $heliotrope 87.94%, $white 96.97%);
            position: absolute;
            top: -2px;
            left: -2px;
            background-size: 400%;
            z-index: -1;
            filter: blur(4px);
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            animation: glowing 10s linear infinite;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            border-radius: rem(32);
        }

        &::after {
            z-index: -1;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
            left: 0;
            top: 0;
            border-radius: rem(32);
        }
    }
}
