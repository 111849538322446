// fonts

@font-face {
    font-family: 'Gotham Book';
    src: url('#{$font-path}Gotham-Book.woff2') format('woff2'),
        url('#{$font-path}Gotham-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}Gotham-Medium.woff2') format('woff2'),
        url('#{$font-path}Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}Gotham-Black.woff2') format('woff2'),
        url('#{$font-path}Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}Gotham-Bold.woff2') format('woff2'),
        url('#{$font-path}Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Black.woff2') format('woff2'),
        url('#{$font-path}Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Light.woff2') format('woff2'),
        url('#{$font-path}Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Bold.woff2') format('woff2'),
        url('#{$font-path}Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Medium.woff2') format('woff2'),
        url('#{$font-path}Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-ExtraLight.woff2') format('woff2'),
        url('#{$font-path}Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Regular.woff2') format('woff2'),
        url('#{$font-path}Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Thin.woff2') format('woff2'),
        url('#{$font-path}Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-SemiBold.woff2') format('woff2'),
        url('#{$font-path}Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

