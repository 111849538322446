.o-navigation {
    @include adjust-flex(space-between);
    background-color: $white;
    padding: 0 rem(20);
    height: rem(44);
    width: 100%;
    max-width: rem(600);
    margin-right: auto;
    margin-left: auto;

    &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.3s ease-in-out;
    }

    &_text {
        margin-top: rem(4);
        color: $black;
        font-family: $font-gotham;
        @include font-base(12, 18);
    }
}
