.p-prize {
    &_controls {
        @include adjust-flex(space-between);
        margin-top: rem(26);
    }

    &_button {
        padding-bottom: rem(10);
        position: relative;
        transition: all 0.3s ease-in-out;

        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: calc(100% - 8px);
            background-color: rgba($black, 0.3);
            top: 0;
            left: 0;
            border-radius: rem(32);
            z-index: 1;
        }

        &-active {
            &.p-prize_button {
                &::before {
                    display: none;
                }
            }

            &::after {
                position: absolute;
                content: '';
                width: 60%;
                height: 2px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $white;
            }
        }
    }

    &_table {
        margin-top: rem(10);
    }

    &_img {
        max-width: 100%;
        margin-top: rem(8);
    }

    &_loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(200);
    }
}
