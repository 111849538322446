.p-prizeSpecial {
    $root: &;
    position: relative;
    height: 100%;

    &_container {
        position: relative;
        width: 100%;
        margin-top: calc(30 / 1024 * 100%);

        @include max-large-desktop-down {
            margin-top: calc(25 / 1024 * 100%);
        }

        @include max-desktop-down {
            margin-top: calc(20 / 1024 * 100%);
        }

        &-isResult {
            margin-top: calc(62 / 1024 * 100%);

            @include max-desktop-down {
                margin-top: calc(52 / 1024 * 100%);
            }
        }

        &_numbers {
            width: calc(1100 / 1920 * 100%);
            max-width: rem(1100);
            margin-left: auto;
            margin-right: auto;

            @include max-desktop-down {
                width: calc(1000 / 1920 * 100%);
            }
        }

        &_body {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_left {
            width: calc(550 / 1920 * 100%);
            flex-shrink: 0;

            img {
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
            }

            &_button {
                width: rem(220);
                height: rem(86);
                margin-left: auto;
                margin-right: auto;

                .a-button {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &_right {
            flex-shrink: 0;
            width: calc(500 / 1920 * 100%);
        }

        &_center {
            img {
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                max-height: rem(700);

                @include max-large-desktop-down {
                    max-height: rem(460);
                }

                @include max-desktop-down {
                    max-height: rem(350);
                }
            }

            &_button {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: rem(10);
            }
        }

        &-backup {
            margin-top: calc(30 / 1024 * 100%);

            @include max-large-desktop-down {
                margin-top: calc(21 / 1024 * 100%);
            }
        }

        .m-buttonLuckyDraw {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &_tableSpecial {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .t-drawLayout_title {
        padding-top: calc(20 / 1080 * 100%);

        @include max-large-desktop-down {
            padding-top: calc(18 / 1080 * 100%);
        }

        @include max-desktop-down {
            padding-top: calc(14 / 1080 * 100%);
        }
    }

    &-result {
        .t-drawLayout_title {
            padding-top: calc(32 / 1080 * 100%);
        }

        #{$root}_container-backup {
            margin-top: calc(44 / 1024 * 100%);
        }
    }

    &-backup {
        .t-drawLayout_title {
            padding-top: calc(40 / 1080 * 100%);

            @include max-large-desktop-down {
                padding-top: calc(10 / 1080 * 100%);
            }
        }

        .o-framePrize_item {
            margin-bottom: rem(32);

            @include max-large-desktop-down {
                margin-bottom: rem(24);
            }
        }
    }

    &-resultBackup {
        .t-drawLayout_title {
            padding-top: calc(32 / 1080 * 100%);
        }

        .p-prizeFirst_container-backup {
            margin-top: calc(70 / 1024 * 100%);
        }
    }
}
