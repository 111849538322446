.a-checkbox {
    $root: &;

    &_wrapper {
        @include adjust-flex(flex-start, flex-start);
        cursor: pointer;
        width: fit-content;
    }

    input {
        flex-shrink: 0;
        position: relative;
        appearance: none;
        cursor: pointer;
        width: rem(24);
        height: rem(24);
        border-radius: 4px;
        border: 1px solid $eminence;
        background: $white;
        transition: border-color $transition ease-in-out;
        margin: 0;


        
        // &:checked {
        //     background-image: url('~assets/icons/ic_checkboxIcon.svg');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-size: auto;
        //     display: block;
        // }
    }
}
