.o-framePrize {
    &_wrap {
        border-radius: rem(8);
    }

    &_item {
        padding: 0 rem(6);
        margin-bottom: rem(40);

        @include max-desktop-down {
            margin-bottom: rem(20);
        }
    }
}
