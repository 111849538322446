.o-introduceNabati {
    width: 100%;
    flex-shrink: 0;

    &_imgNabati {
        width: rem(92);
    }

    &_imgNabati,
    &_imgGoGuma {
        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    &_imgNabati {
        max-width: rem(120);
    }

    &_imgGoGuma {
        margin-top: rem(8);
        max-width: 62%;

        &_star {
            @include animation-stars();
        }
    }
}
