.m-formField {
    &_label {
        @include font-base(14, 18);
        margin-left: rem(8) !important;
    }

    &_error {
        color: $red-pigment;
    }

    &_input {
        margin-top: rem(4);
    }
}
