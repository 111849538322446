.o-prize {
    $root: &;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;

    &_number {
        position: relative;
        width: rem(83);
        height: rem(83);
        margin-left: rem(10);
        margin-right: rem(10);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        box-shadow: -1.9px 2.2px 4px 2px $deep-violet-07;
        border-radius: rem(6);
        border: 2px solid $heliotrope;
        // background-image: linear-gradient(to right top, $heliotrope 100%, $bright-lilac 90%, $medium-orchid 78%, $dark-lavender 0%);

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            z-index: -1;
            background-color: $white;
        }

        @include max-large-desktop-down {
            width: rem(72);
            height: rem(72);
        }

        @include max-desktop-down {
            width: rem(60);
            height: rem(60);
        }

        p {
            font-size: rem(48);
            color: $eminence;
            font-weight: 700;
            letter-spacing: 0.6px;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include max-large-desktop-down {
                font-size: rem(42);
            }

            @include max-desktop-down {
                font-size: rem(36);
            }
        }
    }

    &-special {
        #{$root}_number {
            width: rem(90);
            height: rem(90);
            border-radius: rem(10);

            @include max-large-desktop-down {
                width: rem(72);
                height: rem(72);
            }

            @include max-desktop-down {
                width: rem(60);
                height: rem(60);
            }

            p {
                font-size: rem(50);

                @include max-large-desktop-down {
                    font-size: rem(42);
                }

                @include max-desktop-down {
                    font-size: rem(38);
                }
            }
        }
    }
}
