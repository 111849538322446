.a-inputCustom {
    &_input {
        padding: 0 rem(8) !important;
        height: rem(36);
        border-radius: rem(8) !important;
        border: 1px solid $purpureus;

        input {
            padding: 0 !important;
            color: $eminence;
            @include font-base(12, 18);

            &::placeholder {
                @include font-base(12, 18);
                color: $eminence !important;
            }
        }

        &-error {
            border-color: $red-pigment !important;
        }
    }
}
