.o-notify {
    &_popup {
        .o-modal_main {
            max-width: rem(460);
        }
    
        .o-modal_body {
            min-height: rem(120);
        }

        &_desc {
            margin-top: rem(16);
        }
    }
}
