.t-mainLayout {
    display: flex;
    flex-direction: column;
    background: url('~assets/images/background.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    overflow: hidden;

    &_header {
        flex-shrink: 0;
        width: 100%;
        height: rem(44);
        padding: rem(8) 0 rem(12);
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-header;

        button {
            @include reset-button(); 
            display: flex;
            color: $white;
            align-items: center;

            p {
                text-shadow: 1px 1px 1px $black-075;
                font-weight: bold;
            }
        }

        &_back {
            font-size: rem(14);
        }
    }

    &_introduce {
        margin-top: rem(30);
    }

    &_content {
        position: relative;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        max-width: rem(600);
        margin-left: auto;
        margin-right: auto;
        padding-left: rem(16);
        padding-right: rem(16);
        @include scroll-bars(4, $soap, transparent, 4, 1);
        display: flex;
        flex-direction: column;
    }

    &_footer {
        flex-shrink: 0;
    }
}
