// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$red-pigment: rgb(236, 31, 40); // #ec1f28
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$isabelline: rgb(237, 237, 237); //#ededed
$eminence: rgb(101, 62, 137); //#653e89
$soap: rgb(203, 195, 232); // #cbc3e8
$purpureus: rgb(152, 64, 175); //#9840af
$red-pigment: rgb(236, 31, 40); //#ec1f28
$dark-lavender: rgb(115, 66, 157); //#73429d
$medium-orchid: rgb(183, 81, 242); //#b751f2
$bright-lilac: rgb(216, 148, 247); //#d894f7
$heliotrope: rgb(202, 119, 245); //#ca77f5
$shampoo: rgb(240, 213, 239); //#f0d5ef
$purpureus-2: rgb(155, 71, 177); //#9b47b1
$anti-flash-white: rgb(242, 242, 242); //#f2f2f2
$deep-violet: rgb(63, 0, 104); // #3f0068
$platinum-8e8: rgb(232, 232, 232); // #e8e8e8
$silver: rgb(192, 192, 192); // #c0c0c0

// Overlays
$eminence-05: rgba($eminence, 0.5);
$black-05: rgba($black, 0.5);
$black-03: rgba($black, 0.3);
$black-075: rgba($black, 0.75);
$deep-violet-07: rgba($deep-violet, 0.7);

// Linears
