.t-drawLayout {
    position: relative;
    background-image: url('~assets/images/background_draw.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    z-index: 0;
    // padding-bottom: rem(32);

    &-isResult {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: linear-gradient(287deg, rgba(255, 255, 255, 0.3) 9.65%, rgba(255, 255, 255, 0.3) 107.66%);
        }
    }

    &_logo {
        max-width: rem(204);
        position: absolute;
        width: calc(204 / 1920 * 100%);
        height: auto;
        top: calc(40 / 1080 * 100%);
        left: calc(54 / 1920 * 100%);
        background-size: contain;
        z-index: 10;

        @include max-large-desktop-down {
            width: calc(160 / 1920 * 100%);
            top: calc(30 / 1080 * 100%);
            left: calc(42 / 1920 * 100%);
        }

    }

    &_title {
        position: relative;
        text-align: center;
        @include font-base(58, 65);
        letter-spacing: 0.44px;
        padding-left: rem(20);
        padding-right: rem(20);
        padding-top: calc(40 / 1080 * 100%);
        @include text-gradient(linear-gradient(180deg, #BD62F4 0%, #714199 84.5%));
        font-family: $font-gotham;

        @include max-large-desktop-down {
            @include font-base(48, 60);
        }

        @include max-desktop-down {
            @include font-base(42, 58);
            padding-top: calc(14 / 1080 * 100%);
        }

        @include desktop-down {
            @include font-base(36, 42);
        }
    }

    &_isBackup {
        position: relative;
        text-align: center;
        @include font-base(38, 52);
        letter-spacing: 0.44px;
        @include text-gradient(linear-gradient(180deg, #BD62F4 0%, #714199 84.5%));
        font-family: $font-gotham;
        font-weight: 900;
        -webkit-text-stroke: 2px #fff;

        @include max-large-desktop-down {
            @include font-base(32, 46);
        }

        @include max-desktop-down {
            @include font-base(28, 38);
        }

        @include desktop-down {
            @include font-base(24, 32);
        }
    }

    &_content {
        position: relative;
    }

    &_starBottom {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &_starTop {
        position: absolute;
        top: 0;
        right: 0;
    }

    &_star {
        @include animation-stars();
    }
}
