.o-tableResult {
    border-radius: rem(16);
    background-color: $white;
    font-family: $font-gotham;
    box-shadow: -2px 1px 5px 4px #3f00686b;
    overflow: hidden;

    &_tableWrap {
        width: 100%;
        border-collapse: collapse;

        thead {
            background: linear-gradient(0, $dark-lavender 9.55%, $medium-orchid 77.68%, $bright-lilac 87.94%, $heliotrope 96.97%);
        }
    }

    th {
        text-transform: uppercase;
        @include font-base(26, 32);
        font-weight: 400;
        color: $white;
        text-align: center;
        width: 50%;
        padding: rem(16);
        text-shadow: 2px 2px 4px rgba($black, 0.7);

        @include max-large-desktop-down {
            @include font-base(22, 28);
        }

        @include max-desktop-down {
            @include font-base(18, 24);
        }
    }

    td {
        @include font-base(28, 40);
        font-weight: 400;
        color: $dark-lavender;
        text-align: center;
        width: 50%;
        padding: rem(16);
        position: relative;
        overflow: hidden;

        @include max-large-desktop-down {
            @include font-base(24, 36);
            padding: rem(12);
        }

        @include max-desktop-down {
            @include font-base(20, 32);
            padding: rem(8);
        }

        &::before {
            position: absolute;
            content: '';
            width: 80%;
            height: 1px;
            background-color: $eminence;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &:first-child {
            &::after {
                position: absolute;
                content: '';
                width: 1px;
                height: 100%;
                background-color: $eminence;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    tr {
        &:first-child {
            td {
                &::after {
                    bottom: 0;
                    transform: translateY(-20%);
                }
            }
        }

        &:last-child {
            td {
                &::before {
                    display: none;
                }

                &:first-child {
                    &::after {
                        top: 0;
                        transform: translateY(-20%);
                    }
                }
            }
        }
    }
}
