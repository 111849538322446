.m-pulldown {
    $root: &;
    position: relative;

    &-disabled {
        #{$root}__placeholder {
            opacity: 0.3;
        }
    }

    &-error {
        #{$root}__control {
            border: 0.7px solid $red-pigment;

            &:hover {
                border: 0.7px solid $red-pigment;
            }
        }
    }

    /* Customize */
    &__input {
        font-size: rem(16) !important;
        font-family: inherit !important;

        &-container {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    &-menu {
        z-index: 2 !important;
    }

    &__control {
        &--is-focused {
            box-shadow: none !important;
        }

        .a-icon-arrowDown {
            transition: all 0.25s ease-out;
        }

        &--menu-is-open {
            .a-icon-arrowDown {
                transform: rotate(180deg);
            }
        }
    }

    &__single-value {
        // height: rem(28);
        display: flex;
        align-items: center;
    }

    &__singleValue {
        display: flex;
        align-items: center;

        &__icon {
            flex-shrink: 0;
            margin-right: rem(6);

            img {
                width: rem(28);
                height: auto;
            }
        }

        &__text {
            font-size: rem(12);
            line-height: rem(18);
            color: $black;
        }
    }

    &__placeholder {
        font-size: rem(12);
        color: $eminence !important;

        span {
            color: $red-pigment;
            display: inline-block;
            margin-right: rem(4);
        }
    }

    &__indicators {
        padding-left: 0 !important;
        padding-right: rem(8) !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__value-container {
        font-size: rem(12);
        padding-left: rem(8) !important;
        padding-right: 0 !important;
        // height: rem(32);
    }

    &__menu {
        border: 0 !important;
        box-shadow: none !important;

        &-list {
            border-radius: rem(8) !important;
            padding: rem(12) rem(8);
            max-height: rem(200) !important;
            @include scroll-bars(4, $soap, transparent, 4, 1);
            box-shadow: rem(-2) rem(2) rem(8) rgba($eminence, 0.5);
        }
    }

    &__option {
        background-color: $white !important;

        &:hover,
        &--is-selected {
            background-color: $white;

            #{$root}__option__item {
                border: 0.5px solid $eminence;
                background-color: $soap;
                border-radius: rem(4);
                color: $black;
            }
        }

        &__item {
            background-color: $white;
            display: flex;
            align-items: center;

            #{$root}__option__text {
                padding: rem(5) rem(17);
                font-size: rem(12);
                line-height: rem(18);
                border-bottom: 0.5px solid rgba(203, 195, 232, 0.5);
                width: 100%;
            }


            &:has(>.m-pulldown__option__icon) {
                .m-pulldown__option__text {
                    padding-left: 0;
                }
            }
        }

        &__icon {
            width: rem(22);
            height: rem(22);
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // box-shadow: 0px 0px 4px rgba($black-05, 0.5);
            margin-right: rem(6);
            background-color: $white;
            border-radius: rem(2);

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
