html,
body {
    font-family: $font-inter;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }

    &-4 {
        margin-top: rem(4);
    }
}

.u-mb {
    &-24 {
        margin-bottom: rem(24);
    }

    &-16 {
        margin-bottom: rem(16);
    }

    &-12 {
        margin-bottom: rem(12);
    }
}

.z-index {
    &-1 {
        position: relative;
        z-index: 1;
    }

    &-2 {
        position: relative;
        z-index: 2;
    }

    &-3 {
        position: relative;
        z-index: 3;
    }

    &-4 {
        position: relative;
        z-index: 4;
    }
}
