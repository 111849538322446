.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-6 {
        height: rem(6);
        width: rem(6);
    }

    &-8 {
        height: rem(8);
        width: rem(8);
    }

    &-10 {
        height: rem(10);
        width: rem(10);
    }

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-14 {
        height: rem(14);
        width: rem(14);
    }

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-18 {
        height: rem(18);
        width: rem(18);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-80 {
        height: rem(80);
        width: rem(80);
    }

    &-download {
        background-image: url('~assets/icons/ic_download.svg');
    }

    &-arrowDown {
        background-image: url('~assets/icons/ic_arrow_down.svg');
    }

    &-warn {
        background-image: url('~assets/icons/ic_warn.svg');
    }

    &-home {
        background-image: url('~assets/icons/ic_home.svg');
    }

    &-homeActive {
        background-image: url('~assets/icons/ic_home_active.svg');
    }

    &-gift {
        background-image: url('~assets/icons/ic_gift.svg');
    }

    &-giftActive {
        background-image: url('~assets/icons/ic_gift_active.svg');
    }

    &-support {
        background-image: url('~assets/icons/ic_support.svg');
    }

    &-supportActive {
        background-image: url('~assets/icons/ic_support_active.svg');
    }

    &-sort {
        background-image: url('~assets/icons/ic_sort.svg');
    }

    &-close {
        background-image: url('~assets/icons/ic_close.svg');
    }

    &-back {
        background-image: url('~assets/icons/ic_back.svg');
    }

    &-email {
        background-image: url('~assets/icons/ic_email.svg');
    }

    &-phone {
        background-image: url('~assets/icons/ic_phone.svg');
    }

    &-facebook {
        background-image: url('~assets/icons/ic_facebook.svg');
    }

    &-zalo {
        background-image: url('~assets/icons/ic_zalo.svg');
    }

    &-checkBlue {
        background-image: url('~assets/icons/ic_check_blue.svg');
    }

    &-checkOrange {
        background-image: url('~assets/icons/ic_check_orange.svg');
    }

    &-www {
        background-image: url('~assets/icons/ic_www.svg');
    }

    &-emailContact {
        background-image: url('~assets/icons/ic_email_contact.svg');
    }

    &-phoneContact {
        background-image: url('~assets/icons/ic_hotline_contact.svg');
    }
}
